.wrapper {
  @apply flex flex-col gap-5 p-8 mb-8 bg-white shadow md:flex-row;

  & :global(.search-field) {
    @apply flex items-center flex-1 h-16 px-4 mb-0 overflow-hidden border rounded border-black-100/25;
    @apply relative;

    & input {
      @apply w-full h-16 text-lg focus:outline-none;
    }

    & :global(.clear-search) {
      @apply absolute top-5.5 right-3 bg-summer-green-100 w-5 h-5 p-[5px] rounded mr-3 cursor-pointer;
      & svg {
        @apply w-full h-auto;
      }
    }
  }

  & :global(.grade-filter) {
    @apply flex-1;

    & select {
      @apply h-16 border-black-100/25;
    }
  }

  & :global(.activitites-filter) {
    @apply flex-1;

    & ul {
      @apply grid-cols-1;
    }
  }
}
