.main {
  @apply flex flex-col md:flex-row justify-between;

  & :global(.content-wrapper) {
    @apply md:w-[57%] mx-0;
    @apply font-serif text-lg md:text-xl leading-8 overflow-hidden;

    & :global(.lead) {
      @apply text-lg sm:text-2xl;
    }

    & h1 {
      @apply mt-2 sm:mt-0 mb-6 sm:mb-4;
    }

    & :global(.body) {
      & h1 {
        /* Supress additional h1 in body */
        @apply text-2xl;
      }
    }

    & figure {
      @apply w-full max-w-full relative aspect-w-4 aspect-h-3;
      @apply mb-4 sm:mb-6;

      &:global(.has-caption) {
        @apply mb-10;
      }
    }

    & figcaption {
      @apply absolute right-0 top-[100%] pt-2 text-sm text-right text-black-100/90 w-full h-auto;
    }

    & p {
      @apply pb-6 leading-8;

      & a {
        @apply underline;
      }
    }

    & ol,
    & ul {
      @apply list-disc list-inside pb-7;

      & li > ul,
      & ol {
        @apply pl-5;
      }
    }

    & ol {
      @apply list-decimal;
    }

    & img {
      @apply w-full pb-7;
    }

    & a {
      text-decoration: underline;
    }

    & :global(.type a) {
      /* mb-2.5 block cursor-pointer */
      @apply no-underline;
    }

    & :global(.information) {
      @apply bg-summer-green-50 p-4 mb-8 text-base rounded;

      & h2 {
        @apply text-xl;
      }

      & :global(.label) {
        @apply pr-2 font-sans font-bold;
      }

      & ul {
        @apply list-none pb-0;
      }

      & button {
        @apply mt-4;
      }
    }

    & :global(.type) {
      @apply inline-block;
    }

    & :global(.available-seats) {
      @apply text-xs font-bold font-sans tracking-wider uppercase inline-block mb-1;
      @apply p-2 bg-green-25 mr-3;

      & :global(.dot) {
        @apply inline-block w-2 h-2 rounded-full bg-green-100 mr-1.5;
      }

      &:global(.is-loading) {
        @apply bg-black-100/10;
        & :global(.dot) {
          @apply bg-black-50;
        }
      }
    }
  }

  & :global(.meta-wrapper) {
    @apply md:w-[40%] mx-0 overflow-hidden;

    & :global(.inner-wrapper) {
      @apply bg-summer-green-50 py-2;
    }

    & section {
      @apply mx-6 lg:mx-8 my-4 lg:my-6 pb-6 border-b border-black-100/10;
      @apply last-of-type:border-0 last-of-type:mb-0 text-base lg:text-lg;

      & li a {
        @apply underline;
      }

      & :global(.email) {
        @apply select-none overflow-hidden text-ellipsis;
      }
    }

    & h2 {
      @apply text-xl md:text-2xl mb-2;
    }

    & :global(.label) {
      @apply pr-2 font-sans font-bold;
    }

    & :global(.text) {
      @apply text-right break-words sm:break-normal;
    }

    & :global(.button-wrapper) {
      @apply fixed w-full left-0 bottom-0 z-90;
      @apply bg-white p-4 md:bg-transparent md:static md:p-0;

      & :global(.btn) {
        @apply w-full mt-4;
      }

      &:global(.hide-button) {
        @apply hidden md:block;
      }
    }

    & :global(.with-icons) {
      @apply pt-2 font-serif;

      & li {
        @apply flex items-center mb-2;
      }
    }

    & :global(.circle) {
      @apply bg-white w-12 h-12 rounded-full mr-4;
      & :global(.icon) {
        @apply flex items-center justify-center w-full h-full;
      }
    }

    & :global(.location) {
      & ul {
        @apply mb-4;
      }

      & a {
        @apply underline;
      }
    }

    & :global(.favorite-event) {
      & p {
        @apply pt-4 text-center;
        & a {
          @apply font-bold underline;
        }
      }

      & :global(.btn) {
        @apply w-full flex justify-center items-center;
      }

      & :global(.circle) {
        @apply w-5.5 h-5.5 bg-white rounded-full flex justify-center items-center;
      }

      & :global(.is-favorite) {
        @apply bg-summer-green-23;
        & :global(.circle) {
          @apply bg-summer-green-100;
        }
      }
    }
  }
}

.crumbs {
  @apply pt-4 mb-3 lg:mb-8;
}

.map {
  @apply bg-white relative aspect-w-[452] aspect-h-[286] mt-4 mb-2;

  & :global(.inner-wrapper) {
    @apply w-full h-full;
  }

  & :global(.marker) {
    @apply w-5 h-5 rounded-full bg-black-100 border-2 border-white;
  }
}

.hero {
  @apply bg-summer-green-20;

  & :global(.inner-wrapper) {
    @apply flex flex-col lg:flex-row justify-between mt-0 lg:mt-8;
    /* @apply grid items-center grid-cols-1 gap-x-24 gap-y-10 lg:grid-cols-2; */
    @apply max-w-8xl box-content px-4 md:px-8 mx-auto mb-4 sm:mb-8 lg:mb-14;
    /* @apply lg:mb-16; */

    /* 520 / 112 / 672 */

    & :global(.heading) {
      @apply w-full lg:w-[51%] flex flex-col justify-center;
    }

    & figure {
      @apply w-full lg:w-[41%] relative;

      & div {
        @apply bg-black-100/5 aspect-w-1 aspect-h-1 md:aspect-w-4 md:aspect-h-3;
        @apply mb-4 md:mb-8 lg:mb-0;
      }
    }

    & :global(.badge-wrapper) {
      @apply absolute top-[16px] right-[80px] md:-top-[25px] md:right-[75px] z-10;

      & :global(.category-badge) {
        @apply w-22 h-22 bg-summer-green-100 rounded-full;
        @apply flex justify-center items-center font-serif font-bold text-base;
        @apply rotate-[15deg];
      }
    }
  }

  & :global(.panel-wrapper) {
    @apply pb-8 mx-auto flex justify-center;
  }

  & :global(.info-box) {
    @apply w-full max-w-8xl p-6 md:p-8 lg:p-10 mx-4 md:mx-8 bg-white shadow;
    @apply grid grid-cols-1 gap-4 lg:gap-8 lg:p-8 md:grid-cols-2 lg:grid-cols-4;

    & :global(.badge-wrapper) {
      @apply flex-none w-12 h-12 mt-1 mr-3 rounded-full bg-summer-green-50;

      & :global(.icon) {
        @apply flex items-center justify-center w-full h-full;
      }
    }

    & :global(.section) {
      @apply overflow-hidden;

      & :global(.content) {
        @apply flex mt-2;
      }

      & :global(.data) {
        @apply mt-2 font-sans text-sm leading-5;
      }

      & :global(.email) {
        @apply break-all;
      }
    }
  }
}

.form {
  @apply bg-summer-green-20 py-10;

  & :global(.inner-wrapper) {
    @apply max-w-1xl mx-4 px-4 md:px-8 sm:mx-auto;
    @apply p-5 bg-white border rounded-md sm:p-8 border-black-100/20;
  }

  & :global(.current-status) {
    @apply max-w-1xl mx-4 px-4 md:px-8 sm:mx-auto mb-8 rounded-md;
    @apply bg-black-100/90 text-base text-white;
    @apply px-4 pt-3 pb-4 relative;

    & h4 {
      @apply my-2;
    }

    & span {
      @apply uppercase text-xs font-bold;
      &::before {
        @apply inline-block w-2 h-2 bg-red-100 mr-2 rounded;
        @apply content-[""];
      }
    }
  }

  & :global(.toggle-form-fields) {
    @apply py-2 text-base underline block text-left cursor-pointer;
  }

  & :global(.logged-in-as) {
    @apply pb-4;
  }
}

.meta {
  & :global(.sharing) {
    @apply mt-4;
  }
}

.related {
  @apply bg-summer-green-20 pt-10 pb-10 md:pb-15 lg:pb-20;

  & :global(.inner-wrapper) {
    @apply box-content max-w-8xl mx-auto px-4 md:px-8;
  }
}

.preview {
  @apply bg-summer-green-20 min-h-96 flex justify-center items-center;

  & h3 {
    @apply px-4 md:px-8;
  }
}

.hero {
  & :global(.panel-wrapper) {
    @screen lg {
      background: linear-gradient(0, #ffffff 50%, transparent 0%);
    }
  }
}
