.wrapper {
  @apply absolute bg-black-100 rounded-lg;
  @apply text-white p-4 z-100;
  @apply font-sans text-base;
  @apply cursor-default pr-6;

  @apply w-[calc(100%+3rem)] max-w-[400px];
  @apply top-2 -left-6;

  &:global(.right) {
    @apply w-[400px];
    @apply -translate-y-1/2 left-10;
  }

  & > p {
    @apply font-normal text-base;
  }

  & :global(.close) {
    @apply absolute right-2 top-2 p-2 cursor-pointer;
    & svg {
      @apply w-2.5 h-2.5;
    }
  }

  &:global(.feed-tooltip) {
    @apply left-0 top-10 w-full;
  }

  & a {
    @apply underline text-summer-green-50;
  }
}

.holder {
  &:global(.active) {

    &::before {
      @apply absolute content-[""] border-solid border-transparent;

      @apply left-2.6 -bottom-4 -translate-x-1/2;
      @apply border-l-10 border-r-10 border-b-10 border-t-0;
      @apply border-b-black-100;
    }
    &:global(.right) {
      &::before {
        @apply left-7.5 bottom-auto top-1/2 -translate-x-0 -translate-y-1/2;
        @apply border-l-0 border-r-0 border-b-0;
        @apply border-t-10 border-r-10 border-b-10;
        @apply border-b-transparent border-r-black-100;
      }
    }
  }
}