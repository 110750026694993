.modal {
  @apply absolute rounded-lg bottom-auto right-auto bg-white top-1/2 left-1/2 max-w-md;
  @apply outline-none max-h-[95%];
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);

  & :global(.content-wrapper) {
    @apply flex-grow p-6 md:p-8 sm:p-10 relative;
    fit-content: fit-content;
  }

  & h2 {
    @apply text-xl pb-3 max-w-[85%];
  }

  & :global(.button-wrapper) {
    @apply flex items-center justify-between md:justify-start md:space-x-6;
  }

  & :global(.button) {
    @apply text-lg py-2.5 px-5 font-serif font-bold;
    @apply inline-block w-[calc(50%-10px)] md:w-auto;
  }

  & :global(.click) {
    @apply absolute w-10 h-10 outline-none -right-5 -top-5;
    @apply z-10 flex items-center justify-center;
  }

  & :global(.close) {
    @apply w-6 h-6 text-white rounded-full cursor-pointer bg-black-100;
    @apply flex items-center justify-center;

    & :global(.icon) {
      @apply fill-white w-[10px];
    }
  }

  & input {
    @apply mb-4;
  }

  & label {
    @apply block font-medium pb-1;
  }
}
