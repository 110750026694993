.contentHolder {

  & :global(h2) {
    @apply mb-2;
  }

  & :global(h3) {
    @apply mb-2;
  }

  & :global(h6) {
    @apply text-sm mb-4;
  }

  & :global(a) {
    @apply underline cursor-pointer;
  }

  & :global(p) {
    @apply mb-4;
    &:last-child {
      @apply mb-0;
    }
  }
}