.controller {
  @apply relative text-black-100/60;

  & button {
    @apply h-16 w-full p-4 border border-black-100/25 rounded;
    @apply flex justify-start items-center;
  }

  &:global(.is-open) {
    & button {
      @apply border-black-100/40 rounded-b-none;
    }

    & :global(.caret) {
      @apply rotate-180;
    }

    & :global(.activites) {
      @apply opacity-100 visible -translate-y-[1px];
    }
  }

  &:global(.has-value) {
    @apply text-black-100;
  }

  & :global(.caret) {
    @apply w-[11px] h-auto absolute top-7 right-6 transition-transform duration-75;
  }

  & :global(.clear-filter) {
    @apply absolute top-5.5 right-9 bg-summer-green-100 w-5 h-5 p-[5px] rounded mr-3 cursor-pointer;
    & svg {
      @apply w-full h-auto;
    }
  }

  & :global(.current-icon) {
    @apply inline-block mr-3 w-10 h-10 md:w-12 md:h-12 bg-summer-green-100 rounded-full p-1.5;
    & svg {
      @apply fill-white;
    }
  }

  & :global(.error) {
    @apply mt-2 text-sm text-red-100;
  }

  & :global(.activites) {
    @apply bg-white shadow-md grid gap-6 p-4 pt-0 rounded-b absolute z-100;
    @apply top-full left-0 w-full h-60 overflow-auto border-b border-x border-black-100/40;
    @apply transition-all duration-[0.075s];
    @apply opacity-0 invisible -translate-y-3;
    @apply text-black-100;

    & h5 {
      @apply mb-2;
    }

    & ul {
      @apply grid xl:grid-cols-2 gap-y-1 gap-x-5;
    }

    & :global(.activity) {
      @apply flex items-center gap-4 p-2 cursor-pointer hover:bg-summer-green-25 rounded-full;

      &:global(.is-checked) {
        @apply bg-summer-green-25 rounded-full;
      }

      & input {
        @apply sr-only;
      }

      & :global(.icon) {
        @apply w-10 h-10 md:w-12 md:h-12 bg-summer-green-100 rounded-full p-1.5;
        & svg {
          @apply fill-white;
        }
      }

      & span {
        @apply flex-shrink-[100];
      }
    }
  }
}
